$(document).ready(function(){
	"use strict";
	
	//mobile nav
	$("button.navbar-toggle").click(function(e){
		$("nav").slideToggle("fast");
		e.preventDefault();
		e.stopPropagation();
	});
	$("nav ul.subnav .ddarrow").click(function(e){
		$(this).prev("ul.subnav").slideToggle("fast");
		$(this).toggleClass("open");
		e.stopPropagation();
		console.log("clicked");
	});
	
	//desktopnav
	$("nav li.hassubnav").mouseenter(function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth >= 768) {
			$(this).find("> ul.subnav").fadeIn("fast");
			$(this).addClass("nav_open");
		}
	});	
	$("nav li.hassubnav").mouseleave(function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth >= 768) {
			$(this).find("> ul.subnav").fadeOut(0);
			$(this).removeClass("nav_open");
		}
	});
	
	$("nav li.hassecondlevel").mouseenter(function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth >= 1549) {
			$(this).find("> ul.subnav").fadeIn("fast");
			$(this).addClass("nav_open");
		}
	});	
	$("nav li.hassecondlevel").mouseleave(function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth >= 1549) {
			$(this).find("> ul.subnav").fadeOut(0);
			$(this).removeClass("nav_open");
		}
	});
	
	//add Header BG on scroll
	var scrolledHeader = function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth >= 768) {
			$("header").addClass("scroll");
			$("header img.logo").attr("src", "/assets/images/logo-yms-black.svg");
			$(".arrow").addClass("scrolled");
		}
	};
	var unscrolledHeader = function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth >= 768) {
			$("header").removeClass("scroll");
			$("header img.logo").attr("src", "/assets/images/logo-yms-white.svg");
			$(".arrow").removeClass("scrolled");
		}
	};
	if ($(window).scrollTop()<=50 && !$("body").hasClass("contact")) {
		unscrolledHeader();
		$("header").on("mouseenter", scrolledHeader);
		$("header").on("mouseleave", unscrolledHeader);
		//console.log("if 1");
	} else if ($(window).scrollTop()<=50 && $("body").hasClass("contact")) {
		$("header").off("mouseenter", scrolledHeader);
		$("header").off("mouseleave", unscrolledHeader);
		scrolledHeader();
		//console.log("if 2");
	} else if ($(window).scrollTop()>50) {
		$("header").off("mouseenter", scrolledHeader);
		$("header").off("mouseleave", unscrolledHeader);
		scrolledHeader();
		//console.log("if 3");
	}
	$(window).scroll(function() {
		if ($(window).scrollTop()<=50 && !$("body").hasClass("contact")) {
			unscrolledHeader();
			$("header").on("mouseenter", scrolledHeader);
			$("header").on("mouseleave", unscrolledHeader);
		}
		if ($(window).scrollTop()>50 && !$("body").hasClass("contact")) {
			$("header").off("mouseenter", scrolledHeader);
			$("header").off("mouseleave", unscrolledHeader);
			scrolledHeader();
		}
	});

	//Arrow scroll
	$(".arrow").click(function(){
		$('html, body').animate({
			scrollTop: $('section.hero').height()
		}, 800);
	});
	
	$('.slider').slick({
		
	});
	
	//Stats count up anim
	var countup = function() {
		jQuery('.figure').each(function() {
			var $this = jQuery(this),
			countTo = $this.attr('data-count');
			jQuery({ countNum: $this.text()}).animate({
				countNum: countTo
			},
			{
				duration: 2000,
				easing:'linear',
				step: function() {
					$this.text(Math.floor(this.countNum));
				},
				complete: function() {
					$this.text(this.countNum);
				}
			});  
		});
	};
	$('.stats').viewportChecker({
		offset: 200,
		callbackFunction: function(){
			countup();
		}
	});
	
	
	//Custom CV upload button
	var $cvuploadlabel	 = $('label#cvuploadlabel'),
	cvuploadlabelVal = $cvuploadlabel.html();
	$("#cvupload").on( 'change', function(e) {
		var fileName = '';

		if( this.files && this.files.length > 1 ) {
			fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
		} else if( e.target.value ) {
			fileName = e.target.value.split( '\\' ).pop();
		}
		if( fileName ) {
			$cvuploadlabel.find( 'span' ).html( fileName );
		} else {
			$cvuploadlabel.html( cvuploadlabelVal );
		}
		});

	
	$(window).on("load resize",function(){
		var screenwidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (screenwidth <= 767) {
			$("ul.subnav").attr('style', '');
			$('.diagram .item').matchHeight();
		}
		if (screenwidth >= 768) {
			$("nav").attr('style', '');
		}
	});
});